import { hasFlag } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { useArfStore } from './store'

const medalIcons = ['🥇', '🥈', '🥉']
const getIsMobile = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent)

export function Leaderboard() {
  const countries = useArfStore((state) => state.countries)
  const totalClicks = useArfStore((state) => state.totalClicks)
  const isLeaderboardVisible = useArfStore((state) => state.leaderboardVisible)
  const toggleLeaderboard = useArfStore((state) => state.toggleLeaderboard)
  const isMobile = getIsMobile()

  return (
    <>
      <div
        className={isLeaderboardVisible ? 'yo3' : 'yo'}
        style={{
          position: 'fixed',
          bottom: 0,
          width: isMobile ? '99%' : '50%',
          marginTop: '2rem',
          cursor: 'pointer',
          padding: '10px',
          pointerEvents: 'auto',
          color: 'black', // Make text color black
          left: isMobile ? '0%' : '25%'
        }}
        onClick={toggleLeaderboard}>
        <div>
          {isLeaderboardVisible ? <h3 style={{ fontSize: '32px', margin: 'auto', color: 'black' }}>Leaderboard</h3> : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '18px',
              color: 'black'
            }}>
            <h2>Total Arfs</h2>
            <span>{totalClicks}</span>
          </div>
        </div>
        {isLeaderboardVisible ? (
          <div>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {Object.entries(countries)
                .sort(([, a], [, b]) => b - a) // Sort countries by click count
                .map(([country, count], index) => (
                  <li key={index} style={{ fontSize: '20px', margin: '5px 0', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {index < 3 ? medalIcons[index] : ''} {/* Show medals for top 3 */}
                      {hasFlag(country) ? getUnicodeFlagIcon(country) : '🏳️'} {country}
                    </span>
                    <span>{count}</span>
                  </li>
                ))}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  )
}
