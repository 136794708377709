import React from 'react'
import { Navbar } from './Navbar'
import { ArfChat } from './ArfChat'

export function About() {
  return (
    <div
      style={{
        height: '100vh',
        overflow: 'auto'
      }}>
      <div
        style={{
          height: '100%',
          padding: '20px',
          maxWidth: '800px',
          margin: '0 auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          alignItems: 'center'
        }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '20px' }}>
          <h1 style={{ fontSize: '90px' }}>
            <a href="/">ARFDOG</a>
          </h1>
        </div>
        <ArfChat />
        <Navbar />
        <p style={{ fontSize: '22px', color: 'black' }}>
          Arfdog is an internet meme famous for his relentless "borks" and YTP remixes. Actually known as Gabe the Dog from Canada, Gabe's
          barking videos became the foundation of countless viral remixes, turning him into a beloved meme icon. Arfdog celebrates Gabe in
          his angriest, funniest moments, paying tribute to his legacy as the internet's most iconic barking meme.
        </p>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', columnGap: '20px' }}>
          <h1 style={{ fontSize: '50px' }}>Meme History</h1>
          <h2>Doggos never die.</h2>
        </div>

        {/* YouTube videos */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px', marginTop: '40px' }}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/drWnOdoOikg?si=gncGuMPXiJHrEIHJ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/i1H0leZhXcY?si=0R4EzKoNFD4BrNkw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/y3PcelCeraw?si=g7U7RHrT20VgDkDX"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/VQS4uUtm2kw?si=O9tgU98KxBHASqP5"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '20px' }}>
          <h1 style={{ fontSize: '50px' }}>Tokenomics</h1>
        </div>

        <ul style={{ textAlign: 'left', color: 'black' }}>
          <li>No pre-sale, no insiders, max 1B supply</li>
          <li>Ownership renounced, immutable</li>
          <li>Fully audited smart contracts</li>
          <li>Buy and sell at any time</li>
          <li>70% LP tokens locked for top 50 holders</li>
        </ul>

        <div>
          <span>
            CA: <span style={{ color: 'green' }}>3uLh</span>Fg96W9bfyTZzaBJosWRtHhMpyDz5nznZAXRe<span style={{ color: 'green' }}>b3SH</span>
          </span>
        </div>

        {/* Legal disclaimer */}
        <div style={{ marginTop: '40px', fontSize: '14px', color: 'black' }}>
          <p>
            Legal Disclaimer: $ARFDOG is a meme coin with no intrinsic value or expectation of financial return. $ARFDOG is for
            entertainment purposes only. When you purchase $ARFDOG, you are agreeing that you have seen this disclaimer.
          </p>
        </div>

        <div style={{ fontSize: '12px' }}>© 2024 - Arfdog Solana</div>
      </div>
    </div>
  )
}
