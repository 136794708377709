import { useEffect } from 'react'
import { useArfStore } from './store'

export function useLocalStorage() {
  const setSessionClicks = useArfStore((state) => state.incrementSessionClicks)
  const setIpInfo = useArfStore((state) => state.setIpInfo)

  useEffect(() => {
    const cachedSessionClicks = localStorage.getItem('sessionArfs')
    if (cachedSessionClicks) {
      setSessionClicks(parseInt(cachedSessionClicks, 10))
    }

    const cachedIpInfo = localStorage.getItem('ipInfo')
    if (cachedIpInfo) {
      setIpInfo(JSON.parse(cachedIpInfo))
    }
  }, [])
}
