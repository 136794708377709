import { useArfStore } from './store'
import { useSocketReady } from './useSocketReady'
import { useLocalStorage } from './useLocalStorage'
import { useSocketBroadcastMessage } from './useSocketBroadcastMessage'
import { useSocketTotalClicks } from './useSocketTotalClicks'
import { useSocketDisconnect } from './useSocketDisconnect'
import { Navbar } from './Navbar'
import { ArfChat } from './ArfChat'
import { Leaderboard } from './LeaderBoard'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export function Overlay() {
  const sessionClicks = useArfStore((state) => state.sessionClicks)
  const sessionClicksRef = useRef(null) // Create a ref for the sessionClicks element
  const navigate = useNavigate()

  useSocketReady()
  useLocalStorage()
  useSocketBroadcastMessage()
  useSocketTotalClicks()
  useSocketDisconnect()

  // Trigger the animation when sessionClicks changes
  useEffect(() => {
    if (sessionClicksRef.current) {
      sessionClicksRef.current.classList.remove('expand-effect') // Remove animation class
      // Force reflow to restart the animation (this prevents rapid clicks from interrupting it)
      void sessionClicksRef.current.offsetWidth
      sessionClicksRef.current.classList.add('expand-effect') // Re-add animation class
    }
  }, [sessionClicks])

  const goToAbout = () => {
    navigate('/about') // Navigate to the About page
  }

  return (
    <div style={{ position: 'absolute', top: 0, width: '100%', pointerEvents: 'none', textAlign: 'center', zIndex: 50 }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* New About Button */}
        <Navbar />
        <button
          onClick={goToAbout}
          style={{
            background: 'none',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            fontSize: '18px',
            textTransform: 'uppercase',
            pointerEvents: 'auto'
          }}>
          ABOUT $ARFDOG
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '20px' }}>
        <h1 style={{ fontSize: '90px' }}>ARFDOG</h1>
      </div>
      <div>
        <h2
          ref={sessionClicksRef} // Add the ref here
          style={{ fontSize: '60px' }}>
          {sessionClicks}
        </h2>
      </div>
      <ArfChat />
      <Leaderboard />
    </div>
  )
}
