import { useContext, useState, useEffect, useRef } from 'react'
import { RegExpMatcher, TextCensor, englishDataset, englishRecommendedTransformers, fixedPhraseCensorStrategy } from 'obscenity'
import { hasFlag } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { useArfStore } from './store'
import { SocketContext } from './context'
import Draggable from 'react-draggable'

const getIsMobile = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent)

const matcher = new RegExpMatcher({
  ...englishDataset.build(),
  ...englishRecommendedTransformers
})
const strategy = fixedPhraseCensorStrategy('arf')
const censor = new TextCensor().setStrategy(strategy)

export function ArfChat() {
  const chatMessages = useArfStore((state) => state.chatMessages)
  const socketReady = useArfStore((state) => state.socketReady)
  const chatVisible = useArfStore((state) => state.chatVisible)
  const toggleChat = useArfStore((state) => state.toggleChat)
  const socket = useContext(SocketContext)
  const isMobile = getIsMobile()
  const [message, setMessage] = useState('')

  const messageListRef = useRef(null)

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
  }, [chatMessages])

  if (!chatVisible) return null

  // Ensure socket is connected before sending the message
  const ensureSocketConnected = () => {
    return new Promise((resolve) => {
      if (socket.connected) {
        resolve(true)
      } else {
        console.log('Socket is not connected. Reconnecting...')
        socket.connect()

        // Wait for the socket to reconnect
        socket.once('connect', () => {
          console.log('Socket reconnected')
          resolve(true)
        })
      }
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!message?.length || message.length > 200) return // Limit to 200 characters

    await ensureSocketConnected() // Ensure socket is connected before emitting

    const matches = matcher.getAllMatches(message)
    const censored = censor.applyTo(message, matches)
    const ipInfo = JSON.parse(localStorage.getItem('ipInfo'))

    // Emit the message event with the country code
    socket.emit('message', { message: censored, country: ipInfo?.location?.country?.code || 'Unknown' })
    setMessage('')
  }

  const onChange = (e) => {
    if (e.target.value.length <= 200) {
      setMessage(e.target.value)
    }
  }

  // Helper function to convert UTC to local time
  const formatTime = (timestamp) => {
    const date = new Date(timestamp)
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
  }

  // Sort chat messages by timestamp, oldest to newest
  const sortedMessages = [...chatMessages].sort((a, b) => a.timestamp - b.timestamp)

  return (
    <Draggable handle=".chat-header">
      <div
        style={{
          width: isMobile ? '99%' : '50%',
          margin: '0 auto',
          padding: '10px',
          position: 'absolute',
          top: '70%',
          left: isMobile ? '0%' : '25%',
          pointerEvents: 'auto',
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '15px',
          border: '2px solid',
          animation: 'pulse 2s infinite'
        }}
        className="yo2">
        <div
          style={{
            backgroundColor: 'black',
            padding: '5px',
            borderRadius: '12px 12px 0 0',
            opacity: 0.75
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <h3 className="chat-header" style={{ margin: 0, color: 'white', textAlign: 'center', width: '100%', cursor: 'move' }}>
              <span style={{ color: 'red' }}>Arf</span>Chat
            </h3>
            <button
              onClick={toggleChat}
              style={{
                cursor: 'pointer',
                background: 'transparent',
                border: 'none',
                color: 'white',
                fontSize: '16px',
                pointerEvents: 'auto',
                width: '20px',
                height: '20px'
              }}>
              X
            </button>
          </div>
          <div style={{ fontSize: '12px' }}>Arfdog has short memory (24hrs)</div>
        </div>

        <div ref={messageListRef} style={{ flexGrow: 1, overflowY: 'auto', padding: '10px' }}>
          {sortedMessages.map((msg, index) => {
            if (!msg || !msg.message) {
              return null // Skip undefined or malformed messages
            }

            const isLongMessage = msg.message.length > 100

            return (
              <div
                key={index}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '150px 1fr 100px',
                  padding: '5px 0'
                }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', color: 'black' }}>
                  <span>{hasFlag(msg.country) ? getUnicodeFlagIcon(msg.country) : '🏳️'}</span>
                  <span>{msg.userName}</span>
                </div>

                <div
                  style={{
                    fontWeight: 'normal',
                    color: 'black',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '400px'
                  }}
                  title={isLongMessage ? msg.message : ''}>
                  {isLongMessage ? `${msg.message.slice(0, 100)}...` : msg.message}
                </div>

                <div style={{ textAlign: 'right', fontSize: '12px', color: 'black' }}>{formatTime(msg.timestamp)}</div>
              </div>
            )
          })}
        </div>

        <div style={{ display: 'flex', columnGap: 5, justifyContent: 'center' }}>
          <form onSubmit={onSubmit} style={{ pointerEvents: 'auto', display: 'flex', columnGap: 4 }}>
            <input
              id="message"
              value={message}
              onChange={onChange}
              placeholder="Message"
              autoComplete="off"
              style={{ pointerEvents: 'auto', color: 'black' }}
            />
            <button type="submit" disabled={!socketReady} style={{ cursor: 'pointer', pointerEvents: 'auto' }}>
              Arf
            </button>
          </form>
        </div>
      </div>
    </Draggable>
  )
}
