import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'

// Define a new uniform for handling click state
const WaveMaterial = shaderMaterial(
  {
    time: 0,
    resolution: new THREE.Vector2(),
    pointer: new THREE.Vector2(),
    isClicked: 0 // Uniform to control the state
  },
  /* Vertex Shader */
  /*glsl*/ `
      varying vec2 vUv;
      void main() {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
        gl_Position = projectionPosition;
        vUv = uv;
      }`,
  /* Fragment Shader */
  /*glsl*/ `
      uniform float time;
      uniform vec2 resolution;
      uniform float isClicked;
      varying vec2 vUv;

      // Function to create smooth wave-like movement for calm state
      float smoothWaves(vec2 uv, float speed) {
        return sin(uv.x * 5.0 + time * speed) * 0.1 + cos(uv.y * 5.0 + time * speed) * 0.1;
      }

      // Random function for chaotic state
      float random(vec2 st) {
        return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
      }

      // Function to create shifty, random noise for chaotic state
      float chaoticNoise(vec2 st, float shiftFactor) {
        vec2 i = floor(st);
        vec2 f = fract(st);
        float a = random(i);
        float b = random(i + vec2(1.0, 0.0));
        float c = random(i + vec2(0.0, 1.0));
        float d = random(i + vec2(1.0, 1.0));
        vec2 u = f * f * (3.0 - 2.0 * f);
        float shift = sin(time * shiftFactor + st.y * 5.0) * 0.2;
        return mix(a, b, u.x) + (c - a) * u.y * (b - a) + shift;
      }

      void main() {
        vec2 uv = vUv * 2.0 - 1.0;
        vec3 color = vec3(0.0);
        
        // Calm state: smooth flowing waves with a gradient
        if (isClicked < 0.5) {
          float wave = smoothWaves(uv, 2.0); // Smooth wave movement
          vec3 calmColor = vec3(0.2 + 0.1 * sin(time), 0.6 + 0.1 * cos(time), 0.9); // Dynamic gradient
          color = calmColor + vec3(wave); // Add waves to gradient
        } 
        // Chaotic red state: random shifting noise with more irregular movement
        else {
          float shiftFactor = 10.0;
          float noiseEffect = chaoticNoise(uv * 10.0 + time * shiftFactor, shiftFactor);
          color = vec3(1.0, 0.0, 0.0) + vec3(noiseEffect); // Red chaos with shifty noise
        }

        gl_FragColor = vec4(color, 1.0);
      }`
)

extend({ WaveMaterial })

export { WaveMaterial }
