import { useContext, useEffect } from 'react'
import { SocketContext } from './context'
import { useArfStore } from './store'

export function useSocketTotalClicks() {
  const socket = useContext(SocketContext)
  const setCountries = useArfStore((state) => state.setCountries)
  const setTotalClicks = useArfStore((state) => state.setTotalClicks)

  useEffect(() => {
    socket.on('totalClicks', (packet) => {
      setTotalClicks(packet.totalClicks)
      setCountries(packet.countries)
    })

    return () => {
      socket.off('totalClicks')
    }
  }, [socket])
}
