import { io } from 'socket.io-client'
import React from 'react'

const local = 'http://localhost:8080'
const prod = 'https://arfdog-xyz-925948624038.us-west1.run.app'
const wsURL = process.env.NODE_ENV === 'production' ? prod : local

// Initialize Socket.IO client with websocket transport only
export const socket = io(wsURL, {
  transports: ['websocket']
})

socket.on('connect', () => {
  console.log('Socket.IO connected')
})

socket.on('disconnect', (reason) => {
  console.log('Socket.IO disconnected, reason:', reason)
})

export const SocketContext = React.createContext(socket)
