import { useContext, useEffect } from 'react'
import { SocketContext } from './context'

export function useSocketDisconnect() {
  const socket = useContext(SocketContext)

  useEffect(() => {
    return () => {
      socket.off('disconnect')
    }
  }, [socket])
}
