import { useContext, useEffect, useState, useRef } from 'react'
import { SocketContext } from './context'
import { useArfStore } from './store'

const getIsMobile = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent)

const calm = '/arfdog1.webp'
const arf = '/arfdog2.webp'
const audioSrc = '/arf.mp3'

export function DogImage() {
  const incrementSessionClicks = useArfStore((state) => state.incrementSessionClicks)
  const dogImage = useArfStore((state) => state.dogImage)
  const setAngry = useArfStore((state) => state.setAngry)
  const setCalm = useArfStore((state) => state.setCalm)
  const isMobile = getIsMobile()
  const socket = useContext(SocketContext)

  // Refs to preload and cache images and audio
  const calmImageRef = useRef(new Image())
  const arfImageRef = useRef(new Image())
  const audioCacheRef = useRef(null)

  useEffect(() => {
    calmImageRef.current.src = calm
    arfImageRef.current.src = arf

    const audio = new Audio(audioSrc)
    audio.load()
    audioCacheRef.current = audioSrc
  }, [])

  const playSound = () => {
    const barkSound = new Audio(audioCacheRef.current)
    barkSound.play()
  }

  // Increment session arfs and store in localStorage
  const incrementSessionArfs = () => {
    incrementSessionClicks()
  }

  // Ensure the socket is connected before emitting events
  const ensureSocketConnected = () => {
    return new Promise((resolve) => {
      if (socket.connected) {
        resolve(true)
      } else {
        console.log('Socket is not connected. Reconnecting...')
        socket.connect()
        socket.once('connect', () => {
          console.log('Socket reconnected')
          resolve(true)
        })
      }
    })
  }

  const emitClickWithCountryCode = async () => {
    // Ensure socket is connected before emitting the event
    await ensureSocketConnected()

    // Retrieve IP info from localStorage
    const ipInfo = JSON.parse(localStorage.getItem('ipInfo'))

    // Emit the incrementClick event with the country code
    if (ipInfo?.location?.country?.code) {
      socket.emit('incrementClick', { country: ipInfo.location.country.code })
    } else {
      // Fallback in case country is not available
      socket.emit('incrementClick', { country: 'Unknown' })
    }

    // Increment session clicks
    incrementSessionArfs()
  }

  const handleTouchStart = () => {
    playSound()
    setAngry()
    emitClickWithCountryCode()
  }

  const handleTouchEnd = () => {
    setCalm()
  }

  const handleMouseDown = () => {
    playSound()
    setAngry()
    emitClickWithCountryCode()
  }

  const handleMouseUp = () => {
    setCalm()
  }

  useEffect(() => {
    const wrapper = document.querySelector('.dog-image-wrapper')

    if (isMobile) {
      wrapper.addEventListener('touchstart', handleTouchStart)
      wrapper.addEventListener('touchend', handleTouchEnd)
    } else {
      wrapper.addEventListener('mousedown', handleMouseDown)
      wrapper.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      if (isMobile) {
        wrapper.removeEventListener('touchstart', handleTouchStart)
        wrapper.removeEventListener('touchend', handleTouchEnd)
      } else {
        wrapper.removeEventListener('mousedown', handleMouseDown)
        wrapper.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isMobile])

  const isCalm = dogImage === calm

  return (
    <div className="dog-image-wrapper">
      <div
        className="dog-image"
        style={{
          backgroundImage: `url(${dogImage})`,
          transform: isCalm ? `scale(1.0)` : `scale(1.1)`
        }}></div>
    </div>
  )
}
