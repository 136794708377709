import { create } from 'zustand'

const calm = '/arfdog1.webp'
const arf = '/arfdog2.webp'
const audioSrc = '/arf.mp3'

export const useArfStore = create((set) => ({
  angry: false,
  dogImage: calm,
  setAngry: () => set({ angry: true, dogImage: arf }),
  setCalm: () => set({ angry: false, dogImage: calm }),
  sessionClicks: 0,
  setSessionClicks: (sessionClicks) => set({ sessionClicks }),
  incrementSessionClicks: () => set((state) => ({ sessionClicks: state.sessionClicks + 1 })),
  socketReady: false,
  setSocketReady: () => set({ socketReady: true }),
  totalClicks: 0,
  setTotalClicks: (totalClicks) => set({ totalClicks }),
  countries: {},
  setCountries: (countries) => set({ countries }),
  sentMessages: [],
  setSentMessages: (message) => set((state) => ({ sentMessages: [...state.sentMessages, message] })),
  ipInfo: null,
  setIpInfo: (ipInfo) => set({ ipInfo }),
  chatMessages: [],
  setChatMessages: (message) => set((state) => ({ chatMessages: [...state.chatMessages, message] })),
  chatVisible: false,
  leaderboardVisible: false,
  toggleChat: () => set((state) => ({ chatVisible: !state.chatVisible, leaderboardVisible: false })),
  toggleLeaderboard: () => set((state) => ({ leaderboardVisible: !state.leaderboardVisible, chatVisible: false }))
}))
