import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { socket, SocketContext } from './context'
import { App } from './App' // Rename default export to named export 'App'
import { About } from './AboutArf' // Assuming you've already created About.js
import { IpregistryClient } from '@ipregistry/client'

export default function Wrapper() {
  // IP Lookup logic remains the same
  const client = new IpregistryClient('ira_BMCyIk06g0nZHxuzsQiS5IxpF2l8x61El6Qr')

  async function lookupIpInfo() {
    const cachedData = localStorage.getItem('ipInfo')
    const cachedTime = localStorage.getItem('ipInfoTimestamp')

    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000
    const currentTime = Date.now()

    if (cachedData && cachedTime && currentTime - cachedTime < ONE_DAY_IN_MS) {
      console.log('IP info loaded from cache:', JSON.parse(cachedData))
      return JSON.parse(cachedData)
    } else {
      try {
        const response = await client.originLookupIp()

        const ipInfo = {
          country: response.data.location.country.code,
          currency: response.data.currency.code,
          isThreat: response.data.security.is_threat
        }

        localStorage.setItem('ipInfo', JSON.stringify(response.data))
        localStorage.setItem('ipInfoTimestamp', currentTime.toString())

        console.log('IP info fetched and cached:', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching IP info:', error)
      }
    }
  }

  useEffect(() => {
    lookupIpInfo()
  }, [])

  return (
    <SocketContext.Provider value={socket}>
      <Router>
        <Routes>
          {/* Render App component only on '/' route */}
          <Route path="/" element={<App />} />
          {/* Render About component on '/about' route */}
          <Route path="/about" element={<About />} />
        </Routes>
      </Router>
    </SocketContext.Provider>
  )
}
