import { useContext, useEffect } from 'react'
import { SocketContext } from './context'
import { useArfStore } from './store'

export function useSocketBroadcastMessage() {
  const socket = useContext(SocketContext)
  const chatMessages = useArfStore((state) => state.chatMessages)
  const setChatMessages = useArfStore((state) => state.setChatMessages)

  const handleFirestoreMessage = (msg) => {
    return {
      ...msg,
      // Convert Firestore Timestamp to Date if necessary
      timestamp:
        msg.timestamp instanceof Object && typeof msg.timestamp.toDate === 'function' ? msg.timestamp.toDate() : new Date(msg.timestamp)
    }
  }

  useEffect(() => {
    socket.on('broadcastMessage', (data) => {
      const alreadyExists = chatMessages.find((msg) => {
        const unixTimestamp = msg.timestamp.getTime()
        return unixTimestamp === data.timestamp && msg.userName === data.userName
      })
      if (alreadyExists) {
        return
      }
      const m = handleFirestoreMessage(data)
      setChatMessages(m)
    })

    return () => {
      socket.off('broadcastMessage')
    }
  }, [chatMessages, socket])
}
