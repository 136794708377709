import { useArfStore } from './store'

export function Navbar() {
  const toggleChat = useArfStore((state) => state.toggleChat)
  const toggleLeaderboard = useArfStore((state) => state.toggleLeaderboard)
  const leaderboardVisible = useArfStore((state) => state.leaderboardVisible)

  const handleChatToggle = () => {
    if (leaderboardVisible) {
      toggleLeaderboard() // Close leaderboard if it is open
    }
    toggleChat() // Then toggle chat
  }

  return (
    <div style={{ display: 'flex', columnGap: '40px', justifyContent: 'center', padding: '20px', cursor: 'pointer' }}>
      <a
        href="https://dexscreener.com/solana/3bbqgn1cketnvlqmwhxxp2by2q3ytgztqfpmvkdkfpuq"
        target="_blank"
        style={{ pointerEvents: 'auto', cursor: 'pointer' }}>
        <img src="/moonshot.svg" alt="Moonshot" style={{ height: '60px' }} />
      </a>
      <a
        href="https://jup.ag/swap/SOL-3uLhFg96W9bfyTZzaBJosWRtHhMpyDz5nznZAXReb3SH"
        target="_blank"
        style={{ pointerEvents: 'auto', cursor: 'pointer' }}>
        <img src="/jupiter.svg" alt="Jupiter" style={{ height: '60px' }} />
      </a>
      <a href="https://x.com/ARFDOGSOLANA" target="_blank" style={{ pointerEvents: 'auto', cursor: 'pointer' }}>
        <img src="/x.svg" alt="X.com" style={{ height: '60px' }} />
      </a>
      <button
        onClick={handleChatToggle}
        style={{
          pointerEvents: 'auto',
          background: 'none',
          border: 'none',
          cursor: 'pointer'
        }}
        className="hover-button">
        <img src="/chat-record-1.svg" alt="Arf Chat" style={{ height: '60px' }} className="svg-image" />
      </button>
    </div>
  )
}
