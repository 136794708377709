import { useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { DogImage } from './DogImage'
import { Overlay } from './Overlay'
import { ShaderPlane } from './ShaderPlane'
import { socket } from './context' // Socket context remains the same

export function App() {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (!socket.connected) {
          console.log('Reconnecting Socket.io after visibility change...')
          socket.connect() // Reconnect the socket
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return (
    <>
      <Canvas style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
        <ShaderPlane />
      </Canvas>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1
        }}>
        <DogImage />
        <audio id="bark-sound" src="/arf.mp3"></audio>
      </div>

      <Overlay />
    </>
  )
}
