import { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { WaveMaterial } from './WaveMaterial'
import { useArfStore } from './store'

const isMobile = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent)

export function ShaderPlane() {
  const angry = useArfStore((state) => state.angry)
  const ref = useRef()
  const { viewport, size } = useThree()

  useFrame((state, delta) => {
    ref.current.time += delta
    ref.current.angry = angry ? 1 : 0
    if (!isMobile()) {
      ref.current.pointer.set(state.mouse.x * 0.5 + 0.5, state.mouse.y * 0.5 + 0.5)
    }
  })

  return (
    <mesh scale={[viewport.width, viewport.height, 1]}>
      <planeGeometry />
      <waveMaterial
        ref={ref}
        key={WaveMaterial.key}
        resolution={[size.width * viewport.dpr, size.height * viewport.dpr]}
        isClicked={angry ? 1 : 0}
      />
    </mesh>
  )
}
