import { useContext, useEffect } from 'react'
import { SocketContext } from './context'
import { useArfStore } from './store'

export function useSocketReady() {
  const socket = useContext(SocketContext)
  const setCountries = useArfStore((state) => state.setCountries)
  const setSocketReady = useArfStore((state) => state.setSocketReady)
  const setTotalClicks = useArfStore((state) => state.setTotalClicks)

  useEffect(() => {
    socket.on('ready', (packet) => {
      setSocketReady(packet.ready)
      setTotalClicks(packet.totalClicks)
      setCountries(packet.countries)
    })

    return () => {
      socket.off('ready')
    }
  }, [socket])
}
